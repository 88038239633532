import React, { FC } from "react"

interface IPageNotFoundProps {
  className?: string
}

const PageNotFound: FC<IPageNotFoundProps> = ({ className }) => (
  <div className={`${className}`}>
    <h1 className="text-center text-2xl">Error 404 - Page Not Found</h1>
  </div>
)

PageNotFound.defaultProps = {
  className: "",
}

export default PageNotFound
